import { Column, UseSortByColumnOptions } from 'react-table';
import {
    amountCellFormatter,
    balanceCellFormatter,
    dateCellFormatter,
    transactionTypeCellFormatter,
} from './CellRenderer';
import { TransactionDetailsType } from './types';

type ColumnType = Column<TransactionDetailsType>[] & UseSortByColumnOptions<TransactionDetailsType>[];

export const columns: ColumnType = [
    {
        Header: 'Transaction Type',
        accessor: 'type',
        Cell: transactionTypeCellFormatter,
        disableSortBy: true,
    },
    {
        Header: 'Amount',
        accessor: 'amount',
        Cell: amountCellFormatter,
    },
    {
        Header: 'Balance',
        accessor: 'balance',
        Cell: balanceCellFormatter,
    },
    {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: true,
    },
    {
        Header: 'Date',
        accessor: 'date',
        Cell: dateCellFormatter,
    },
];
