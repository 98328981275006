import { Backdrop, Button, CircularProgress, Grid } from '@material-ui/core';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { SERVER_URL } from '../constants';
import { TransactionTableWrapper } from './styles';
import { Pagination } from '@material-ui/lab';
import { Spacer } from '../../utils/helpers';
import { DownloadTransactions } from './DownloadTransactions';
import { useHistory } from 'react-router-dom';
import { TransactionListTable } from './TransactionListTable';

export const TransactionListScreen = () => {
    const walletId = localStorage.getItem('walletId');
    const [page, setPage] = useState(1);
    const history = useHistory();

    if (!walletId) {
        history.push('/');
    }

    const { isLoading, isError, data, isFetching, isPreviousData } = useQuery(
        ['projects', page, walletId],
        () => fetchTransactions(walletId, page),
        { keepPreviousData: true },
    );

    if (isError) {
        return (
            <Backdrop open={isError} style={{ zIndex: 1 }}>
                <h3>Some Error has occurred. Please try again later</h3>
            </Backdrop>
        );
    }

    if (isLoading) {
        return (
            <Backdrop open={isLoading} style={{ zIndex: 1 }}>
                <CircularProgress style={{ color: 'black', width: '25px', height: '25px' }} />
            </Backdrop>
        );
    }

    return (
        <TransactionTableWrapper>
            <Backdrop open={isFetching && isPreviousData} style={{ zIndex: 1 }}>
                <CircularProgress style={{ color: 'black' }} />
            </Backdrop>

            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={11}>
                    <Spacer height={30} />
                    <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={() => history.push('/')}>
                                <div style={{ fontWeight: 'bold', marginRight: '5px' }}>Go to Wallet Screen</div>
                            </Button>
                        </Grid>
                        <Grid item>
                            <DownloadTransactions />
                        </Grid>
                    </Grid>
                    <Spacer height={10} />

                    <TransactionListTable data={data?.data || []} />

                    <Spacer height={30} />

                    <Grid container justifyContent="center" alignItems="center">
                        <Pagination
                            page={page}
                            count={10}
                            onChange={(_e, v) => setPage(v)}
                            variant="outlined"
                            shape="rounded"
                        />
                    </Grid>

                    <Spacer height={20} />
                </Grid>
            </Grid>
        </TransactionTableWrapper>
    );
};

const fetchTransactions = (walletId: string | null, page: number) => {
    return axios.get(`${SERVER_URL}/transactions?walletId=${walletId}&skip=${(page - 1) * 10}&limit=${10}`);
};
