import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios';
import { useRef, useState } from 'react';
import { SERVER_URL } from '../constants';
import { Parser } from 'json2csv';
import to from 'await-to-js';
import { CSVLink } from 'react-csv';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

export const DownloadTransactions = () => {
    const walletId = localStorage.getItem('walletId');

    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const downloadCsvRef = useRef(null);

    if (!walletId) {
        history.push('/');
    }

    const handleDownload = async () => {
        setLoading(true);
        const [error, response] = await to(
            axios.get(`${SERVER_URL}/transactions?walletId=${walletId}&skip=0&limit=${10000}`),
        );
        if (error) {
            return toast.error('Failed to download transactions');
        }
        const parser = new Parser();
        const csv = parser.parse(response?.data);
        console.log(csv);
        setData(response?.data);

        setLoading(false);

        if (downloadCsvRef) {
            // @ts-ignore
            downloadCsvRef?.current.link.click();
        }
    };

    return (
        <>
            <Backdrop open={isLoading} style={{ zIndex: 1 }}>
                <CircularProgress style={{ color: 'black', width: '25px', height: '25px' }} />
            </Backdrop>

            <Button color="primary" onClick={() => handleDownload()}>
                <div style={{ fontWeight: 'bold', marginRight: '5px' }}>Download as CSV</div>
                <GetAppIcon />
            </Button>
            <CSVLink filename="transactions.csv" data={data} ref={downloadCsvRef} />
        </>
    );
};
