import { TRANSACTION_TYPE_CREDIT, TRANSACTION_TYPE_DEBIT } from '../../constants';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Controller, useFormContext } from 'react-hook-form';
import { CreditDebitToggleWrapper } from './styles';

export const CreditDebitToggle: React.FC = () => {
    const { control } = useFormContext();

    return (
        <CreditDebitToggleWrapper>
            <Controller
                name="type"
                control={control}
                defaultValue={TRANSACTION_TYPE_CREDIT}
                rules={{
                    required: true,
                }}
                render={({ field }) => {
                    const { onChange } = field;

                    return (
                        <ToggleButtonGroup
                            {...field}
                            exclusive
                            onChange={(_e, v) => {
                                if (v) {
                                    onChange(v);
                                }
                            }}
                        >
                            <ToggleButton
                                disableRipple
                                className={TRANSACTION_TYPE_CREDIT}
                                value={TRANSACTION_TYPE_CREDIT}
                                aria-label={TRANSACTION_TYPE_CREDIT}
                            >
                                <AttachMoneyIcon /> CREDIT
                            </ToggleButton>
                            <ToggleButton
                                disableRipple
                                className={TRANSACTION_TYPE_DEBIT}
                                value={TRANSACTION_TYPE_DEBIT}
                                aria-label={TRANSACTION_TYPE_DEBIT}
                            >
                                <MoneyOffIcon /> DEBIT
                            </ToggleButton>
                        </ToggleButtonGroup>
                    );
                }}
            />
        </CreditDebitToggleWrapper>
    );
};
