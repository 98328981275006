import { Grid } from '@material-ui/core';
import { useContext } from 'react';
import styled from 'styled-components';
import { WalletContext } from '../WalletContext/WalletDetailsContext';
import { SetupWalletCard } from './Sections/SetupWalletCard';
import { WalletDetails } from './Sections/WalletDetails/WalletDetails';

const WalletScreenWrapper = styled.div`
    background: #f3f4f6;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const WalletScreen: React.FC = () => {
    const { walletId } = useContext(WalletContext);

    return (
        <WalletScreenWrapper>
            <Grid container justifyContent="center" alignItems="center">
                {walletId ? <WalletDetails /> : <SetupWalletCard />}
            </Grid>
        </WalletScreenWrapper>
    );
};
