import { Button, Grid } from '@material-ui/core';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Spacer } from '../../../../utils/helpers';
import { WalletContext } from '../../../WalletContext/WalletDetailsContext';
import { WalletBalanceCard } from './WalletBalanceCard';
import { WalletTransactionForm } from './WalletTransactionForm';

export const WalletDetails: React.FC = () => {
    const { logout } = useContext(WalletContext);
    const history = useHistory();

    return (
        //@ts-ignore
        <Grid container spacing={0} align="center" justify="center" direction="column" style={{ flexWrap: 'nowrap' }}>
            {/* <div style={{ position: 'absolute', top: '10px', left: '30px' }}>
                <Button variant="outlined" color="secondary" onClick={() => history.push('/transactions')}>
                    <div style={{ fontWeight: 'bold', marginRight: '5px' }}>Go to transactions Screen</div>
                </Button>
            </div> */}
            <Grid item>
                <Spacer height={10} />
                <WalletBalanceCard />
                <Spacer height={30} />
                <WalletTransactionForm />
                <Spacer height={30} />

                <Grid container xl={3} lg={4} md={6} sm={9} xs={11} direction="row" justifyContent="space-between">
                    <Grid item>
                        <Button variant="outlined" color="secondary" onClick={() => history.push('/transactions')}>
                            <div style={{ fontWeight: 'bold', marginRight: '5px' }}>Go to transactions Screen</div>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" onClick={logout}>
                            Logout
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
