import { Card, CardContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {Controller, FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import { Spacer } from '../../../utils/helpers';
import { countDecimals } from '../../../utils/utils';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import axios from 'axios';
import { SERVER_URL } from '../../constants';
import to from 'await-to-js';
import toast from 'react-hot-toast';
import { WalletContext } from '../../WalletContext/WalletDetailsContext';
import { HeadingWrapper } from './styles';
import { RoundSubmitButton } from '../../InputComponents/SubmitButton/RoundSubmitButton';

export const SetupWalletCard = () => {
    return (
        <Grid item xl={3} lg={4} md={6} sm={9} xs={11}>
            <Card>
                <CardContent>
                    <HeadingWrapper>
                        <Typography
                            align="left"
                            component="h1"
                            style={{ fontWeight: 'bold', fontSize: '22px', opacity: 0.8 }}
                        >
                            Create a new Wallet
                        </Typography>
                        <Typography
                            align="left"
                            variant="subtitle2"
                            gutterBottom
                            style={{ color: '#4f46e5', fontSize: '13px' }}
                        >
                            Add as much money as you want to begin with!!
                        </Typography>
                    </HeadingWrapper>
                    <SetupWalletForm />
                </CardContent>
            </Card>
        </Grid>
    );
};

type WalletSetupFormType = {
    balance: number;
    name: string;
};

export const SetupWalletForm: React.FC = () => {
    const { updateWalletId } = useContext(WalletContext);
    const [savingWallet, setSavingWallet] = useState<boolean>(false);
    const { control, handleSubmit } = useForm();

    const onSubmit = async (data: WalletSetupFormType) => {
        setSavingWallet(true);
        const loadingToast = toast.loading('Setting up your Wallet...');

        const { name, balance } = data;
        const [error, walletDetails] = await to(axios.post(`${SERVER_URL}/setup`, { name, balance }));

        toast.dismiss(loadingToast);
        setSavingWallet(false);

        if (error) {
            toast.error('Failed to set up your wallet');
            return;
        }

        const walletId = walletDetails?.data?.id;
        updateWalletId(walletId);
        toast.success('Successfully setup your wallet');
    };

    return (
        <div style={{ padding: '25px' }}>
            <Spacer height={10} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState: { invalid } }) => (
                        <TextField
                            label="Name"
                            placeholder="Name"
                            error={invalid}
                            {...field}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment color="primary" position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    )}
                />

                <Spacer height={20} />

                <Controller
                    name="balance"
                    control={control}
                    rules={{
                        required: true,
                        validate: {
                            precision: (v) => v && countDecimals(v) < 5 && v > 0,
                        },
                        // @ts-ignore
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { invalid } }) => (
                        <TextField
                            label="Initial Amount"
                            placeholder="Amount"
                            {...field}
                            inputProps={{ min: 0, step: 'any' }}
                            error={invalid}
                            type="number"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalanceWalletIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />
                    )}
                />

                <Spacer height={30} />

                <RoundSubmitButton loading={savingWallet}>Create Wallet</RoundSubmitButton>
            </form>
        </div>
    );
};
