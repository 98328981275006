import { Card, CardContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Spacer } from '../../../../utils/helpers';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { HeadingWrapper } from '../styles';
import toast from 'react-hot-toast';
import { SERVER_URL, TRANSACTION_TYPE_CREDIT } from '../../../constants';
import axios from 'axios';
import {Controller, FieldValues, FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import { useContext, useState } from 'react';
import { WalletContext } from '../../../WalletContext/WalletDetailsContext';
import { countDecimals } from '../../../../utils/utils';
import to from 'await-to-js';
import { CreditDebitToggle } from '../../../InputComponents/CreditDebitToggle/CreditDebitToggle';
import DescriptionIcon from '@material-ui/icons/Description';
import { RoundSubmitButton } from '../../../InputComponents/SubmitButton/RoundSubmitButton';

type WalletTransactionFormType = {
    type: string;
    amount: string;
    description: string;
};

export const WalletTransactionForm: React.FC = () => {
    const { walletId, walletDetails, setWalletDetails } = useContext(WalletContext);
    const formMethods = useForm();
    const { control, handleSubmit, watch } = formMethods;
    const transactionType = watch('type', TRANSACTION_TYPE_CREDIT);

    const [inProgress, setInProgress] = useState<boolean>(false);

    const onSubmit: SubmitHandler<FieldValues>  = async (data) => {
        setInProgress(true);
        const loadingToast = toast.loading('Processing Transaction...');
        const { amount, type, description } = data;

        const updatedAmount = type === TRANSACTION_TYPE_CREDIT ? amount : -amount;

        const [error, response] = await to(
            axios.post(`${SERVER_URL}/transact/${walletId}`, { amount: updatedAmount, description }),
        );
        toast.dismiss(loadingToast);

        setInProgress(false);

        if (error) {
            //@ts-ignore
            toast.error(`Transaction Failed : ${error?.response?.data?.message}`);
            return;
        }

        setWalletDetails({ ...walletDetails, balance: response?.data?.balance });
        toast.success('Transaction Successful');
    };

    return (
        <Grid item xl={3} lg={4} md={6} sm={9} xs={11}>
            <Card>
                <CardContent>
                    <HeadingWrapper>
                        <Typography
                            align="left"
                            component="h1"
                            style={{ fontWeight: 'bold', fontSize: '22px', opacity: 0.8 }}
                        >
                            Make a transaction
                        </Typography>
                        <Typography
                            align="left"
                            variant="subtitle2"
                            gutterBottom
                            style={{ color: '#4f46e5', fontSize: '13px' }}
                        >
                            The amount you enter will be credited/debited from your account
                        </Typography>
                    </HeadingWrapper>
                    <div style={{ padding: '25px' }}>
                        <Spacer height={10} />
                        <FormProvider {...formMethods}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="amount"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: {
                                            precision: (v) => v && countDecimals(v) < 5 && v > 0,
                                        },
                                        // @ts-ignore
                                        valueAsNumber: true,
                                    }}
                                    render={({ field, fieldState: { invalid } }) => (
                                        <TextField
                                            label="Amount"
                                            placeholder="Amount"
                                            {...field}
                                            error={invalid}
                                            type="number"
                                            inputProps={{ min: 0, step: 'any' }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountBalanceWalletIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    )}
                                />

                                <Spacer height={30} />

                                <CreditDebitToggle />

                                <Spacer height={30} />

                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field, fieldState: { invalid } }) => (
                                        <TextField
                                            label="Description"
                                            multiline
                                            fullWidth
                                            placeholder="Where did your money go!!!"
                                            {...field}
                                            error={invalid}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <DescriptionIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                        />
                                    )}
                                />

                                <Spacer height={30} />
                                <RoundSubmitButton loading={inProgress}>
                                    {transactionType === TRANSACTION_TYPE_CREDIT ? 'Accept Payment' : 'Make Payment'}
                                </RoundSubmitButton>
                            </form>
                        </FormProvider>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
};
