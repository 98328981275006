import { createTheme, ThemeProvider } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import './App.css';
import { Routes } from './Components/Routes';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4F46E5',
            dark: '#473fd0',
        },
    },
});
const queryClient = new QueryClient();

export const App = () => {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <Routes />
                    <Toaster />
                </ThemeProvider>
            </QueryClientProvider>
        </div>
    );
};
