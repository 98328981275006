import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SERVER_URL } from '../constants';
import axios from 'axios';
import { CircularProgress, Backdrop } from '@material-ui/core';

type WalletDetailsType = {
    balance?: number;
    date?: string;
    id?: string;
    name?: string;
};

type WalletContextType = {
    walletId?: string | null;
    updateWalletId: (v: string) => void;
    walletDetails?: WalletDetailsType;
    setWalletDetails: (v: WalletDetailsType) => void;
    logout: () => void;
};

export const WalletContext = React.createContext<WalletContextType>({
    updateWalletId: () => {},
    setWalletDetails: () => {},
    logout: () => {},
});

type Props = {
    children: React.ReactNode;
};

export const WalletDataProvider: React.FC<Props> = ({ children }: Props) => {
    const [walletId, setWalletId] = useState<string | null>(localStorage.getItem('walletId'));

    const [walletDetails, setWalletDetails] = useState({});

    const updateWalletId = (walletId: string) => {
        localStorage.setItem('walletId', walletId);
        setWalletId(walletId);
    };
    const logout = () => {
        setWalletId(null);
        localStorage.removeItem('walletId');
        setWalletDetails({});
    };

    const { isLoading, isError } = useQuery(['wallet', walletId], async () => {
        if (walletId) {
            const data = await axios.get(`${SERVER_URL}/wallet/${walletId}`);
            setWalletDetails(data?.data);
        }
    });

    if (isError) {
        logout();
    }

    return (
        <>
            {isLoading && (
                <Backdrop open={isLoading} style={{ zIndex: 1 }}>
                    <CircularProgress style={{ color: 'black', width: '25px', height: '25px' }} />
                </Backdrop>
            )}
            <WalletContext.Provider value={{ walletId, updateWalletId, walletDetails, setWalletDetails, logout }}>
                {children}
            </WalletContext.Provider>
        </>
    );
};
