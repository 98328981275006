import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';

type Props = {
    loading: boolean;
    children: React.ReactNode;
};

export const RoundSubmitButton: React.FC<Props> = (props: Props) => {
    const { loading, children } = props;

    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            style={{ height: '40px', borderRadius: '15px' }}
        >
            {!loading ? (
                children
            ) : (
                <CircularProgress style={{ color: 'white', width: '25px', height: '25px' }} disableShrink />
            )}
        </Button>
    );
};
