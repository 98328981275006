import { Grid } from '@material-ui/core';
import { columns } from './columns';
import { HeaderGroup, useSortBy, useTable } from 'react-table';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TransactionDetailsType } from './types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

type Props = {
    data: TransactionDetailsType[];
};

export const TransactionListTable: React.FC<Props> = (props: Props) => {
    const { data } = props;

    //@ts-ignore
    const tableInstance = useTable({ columns, data }, useSortBy);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
    console.log(rows);

    return (
        <TableContainer component={Paper} {...getTableProps()}>
            <Table aria-label="simple table">
                <TableHeader headerGroups={headerGroups} />
                <TableBody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        const { key, ...rowProps } = row.getRowProps();
                        return (
                            <TableRow key={key} {...rowProps}>
                                {row.cells.map((cell) => {
                                    const { key, ...cellProps } = cell.getCellProps();
                                    return (
                                        <TableCell key={key} {...cellProps}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

type TableHeaderPropType = {
    headerGroups: HeaderGroup<TransactionDetailsType>[];
};

export const TableHeader = (props: TableHeaderPropType) => {
    const { headerGroups } = props;

    return (
        <TableHead>
            {headerGroups.map((headerGroup) => {
                const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

                return (
                    <TableRow key={key} {...headerGroupProps}>
                        {headerGroup.headers.map((column) => {
                            //@ts-ignore
                            const { key, ...headerProps } = column.getHeaderProps(column.getSortByToggleProps());

                            return (
                                <TableCell key={key} {...headerProps}>
                                    <Grid container>
                                        {column.render('Header')}
                                        {
                                            //@ts-ignore
                                            getColumnHeaderIcon(column.canSort, column.isSorted, column.isSortedDesc)
                                        }
                                    </Grid>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableHead>
    );
};

const getColumnHeaderIcon = (canSort: boolean, isSorted: boolean, isSortedDesc: boolean) => {
    return canSort === true ? (
        isSorted ? (
            isSortedDesc ? (
                <ArrowDropDownIcon />
            ) : (
                <ArrowDropUpIcon />
            )
        ) : (
            <ExpandMoreIcon style={{ opacity: 0.5 }} />
        )
    ) : null;
};
