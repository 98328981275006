import styled from 'styled-components';

export const TransactionTableWrapper = styled.div`
    .transaction-type-credit {
        color: green;
        font-weight: bold;
    }

    .transaction-type-debit {
        color: red;
        font-weight: bold;
    }

    .MuiTableCell-head {
        font-weight: 700;
        font-size: 16px;
    }

    .MuiTableCell-root {
        height: fit-content;
    }

    background: #f3f4f6;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-content: center;
`;
