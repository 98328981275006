import { useContext } from 'react';
import { WalletContext } from '../../../WalletContext/WalletDetailsContext';
import { Card, CardContent, Grid, Box } from '@material-ui/core';
import { Spacer } from '../../../../utils/helpers';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Skeleton } from '@material-ui/lab';

export const WalletBalanceCard: React.FC = () => {
    const { walletDetails } = useContext(WalletContext);

    return (
        <Grid item xl={5} lg={6} md={7} sm={9} xs={11}>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-evenly">
                        <Box display="flex" style={{ alignItems: 'center' }}>
                            <AccountCircle color="primary" style={{ height: '35px', width: '35px', opacity: 0.8 }} />
                            <Spacer width={5} />
                            {walletDetails?.name !== undefined ? (
                                <Box
                                    component="div"
                                    textOverflow="ellipsis"
                                    style={{ maxWidth: '130px', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                >
                                    {walletDetails?.name}
                                </Box>
                            ) : (
                                <Skeleton variant="rect" width={80} height={20} />
                            )}
                        </Box>
                        <Box display="flex" style={{ alignItems: 'center' }}>
                            <AccountBalanceWalletIcon
                                color="primary"
                                style={{ height: '35px', width: '35px', opacity: 0.8 }}
                            />
                            <Spacer width={5} />
                            {walletDetails?.balance !== undefined ? (
                                <Box
                                    component="div"
                                    textOverflow="ellipsis"
                                    style={{ maxWidth: '130px', whiteSpace: 'nowrap', overflow: 'hidden' }}
                                >
                                    {walletDetails?.balance}
                                </Box>
                            ) : (
                                <Skeleton variant="rect" width={40} height={20} />
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    );
};
