import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { TransactionListScreen } from './TransactionListScreen/TransactionListScreen';
import { WalletRoute } from './WalletScreen/WalletRoute';

export const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/transactions">
                    <TransactionListScreen />
                </Route>
                <Route path="/">
                    <WalletRoute />
                </Route>
            </Switch>
        </Router>
    );
};
