import styled from 'styled-components';

export const CreditDebitToggleWrapper = styled.div`
    .MuiToggleButton-root.Mui-selected {
        color: #494949;
    }

    .MuiToggleButton-root.Mui-selected.CREDIT {
        background-color: rgb(64 233 52 / 11%);
        border: 1px solid #46e54ac4;
    }

    .MuiToggleButton-root.Mui-selected.DEBIT {
        background-color: rgb(233 110 52 / 11%);
        border: 1px solid #e56a46c4;
    }

    .CREDIT.Mui-selected .MuiSvgIcon-root {
        color: #13ce00;
    }

    .DEBIT.Mui-selected .MuiSvgIcon-root {
        color: red;
    }
`;
