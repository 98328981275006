import { WalletDataProvider } from '../WalletContext/WalletDetailsContext';
import { WalletScreen } from './WalletScreen';

export const WalletRoute = () => {
    return (
        <WalletDataProvider>
            <WalletScreen />
        </WalletDataProvider>
    );
};
