import { CellProps } from 'react-table';
import { TRANSACTION_TYPE_CREDIT, TRANSACTION_TYPE_DEBIT } from '../constants';
import { TransactionDetailsType } from './types';
import moment from 'moment';

const getTransactionTypeClass = (type: string) =>
    type === TRANSACTION_TYPE_CREDIT ? 'transaction-type-credit' : 'transaction-type-debit';

export const transactionTypeCellFormatter = (props: CellProps<TransactionDetailsType>) => (
    <div className={getTransactionTypeClass(props.value)}>{props.value}</div>
);

export const amountCellFormatter = (props: CellProps<TransactionDetailsType>) => {
    const transactionType = props.row.original.type;

    const formattedAmount = `$${transactionType === TRANSACTION_TYPE_DEBIT ? -props.value : props.value}`;

    return (
        <div className={getTransactionTypeClass(transactionType)}>
            {transactionType === TRANSACTION_TYPE_DEBIT ? '-' : '+'} {formattedAmount}
        </div>
    );
};

export const balanceCellFormatter = (props: CellProps<TransactionDetailsType>) => {
    return <div style={{ fontWeight: 'bold' }}>${props.value}</div>;
};

export const dateCellFormatter = (props: CellProps<TransactionDetailsType>) => {
    return <div>{moment(props.value).format('MMMM Do YYYY, h:mm:ss a')}</div>;
};
